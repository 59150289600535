import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import '../Home/Home.scss';
import { Collapse, Upload } from 'antd';
import { ConvertDateShort } from '../../Utils/date';
import QrReader from 'react-qr-reader';

const Scanner = () => {
  const history = useHistory();
  const [scanResult, setScanResult] = useState('');

  const { Panel } = Collapse;

  useEffect(() => {
    return () => {};
  }, []);

  const handleScan = (data) => {
    if (data) {
      history.push(`/checkin?id=${data}`);
      // setScanResult(data)
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div>
      <div className='tab-body-scanner'>
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%', innerHeight: 200, outerHeight: 200 }}
        />
        <p>{scanResult}</p>
      </div>
    </div>
  );
};

export default Scanner;
