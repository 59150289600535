import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../Home/Home.scss";
import "./Summary.scss";
import ApiTranSurvey from "../../Api/ApiTranSurvey";
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import QRCode from 'qrcode-svg';

const Summary = (props) => {
  const history = useHistory();
  const id = new URLSearchParams(props.location.search).get("id");
  const userState = useSelector((state) => state.user);
  const [answer, setAnswer] = useState({});
  const [scorecase, setScoreCase] = useState("");
  
  const dispatch = useDispatch();

  useEffect(() => {
    getAnswerData();
    // dispatch({
    //   type: "set",
    //   page: "/summary",
    // });
    return () => {};
  }, []);

  const getAnswerData = async () => {
    try {
      const result = await ApiTranSurvey.getCheckInById(id);
      if (result.status === 200) {
        var { data } = result.data;
        if (!data || data.length == 0) {
          throw 'Survey Answer Don\'t found';
        }
        console.log("data", data[0]);
        setAnswer(data[0]);
        if (data[0].user_score >= data[0].score_low_min && data[0].user_score <= data[0].score_low_max) {
          setScoreCase("low")
        }
        else if (data[0].user_score >= data[0].score_medium_min && data[0].user_score <= data[0].score_medium_max) {
          setScoreCase("medium")
        }
        else if (data[0].user_score >= data[0].score_high_min && data[0].user_score <= data[0].score_high_max) {
          setScoreCase("high")
        }
      }
      generateAnswerDetail();
    } catch (error) {
      console.log(error);
      Swal.fire('Error', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };

  const generateAnswerDetail = () => {
    return(
      <div>
        <div className="card-tab">
          <div className="card-tab-body">
            <img src={process.env.PUBLIC_URL + answer.image} />
            <div id="flex-body">
              <label
                onClick={() => {
                  history.push(`/summary?id=${answer.survey_id}`);
                }}
              >
                {answer.event_name}
              </label>
              <p>{answer.event_desc}</p>
            </div>
          </div>
          <div className="card-tab-footer-hr"></div>
          <div className="card-tab-footer-summary">
            <p>{answer.answer_name} </p>
            <p>Temperature : {answer.temperature} ํC </p>
            <p>Latitude : {answer.latitude} </p>
            <p>Longitude : {answer.longitude} </p>
          </div>
        </div>
        <div className="half-circle"></div>
        <SummaryBody answer={answer} scorecase={scorecase}/>
        <div className="card-tab-footer-hr2"></div>
        <div className="summary-body-footer">
          <p id={"solid-1"} style={{cursor: "pointer"}} onClick={() => {
            history.push("/home");
          }}>
            <span className="fas fa-times-circle"></span>
            Close
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      {generateAnswerDetail()}
    </div>
  );
};

const SummaryBody = ({answer, scorecase}) => {
  const qrcode = new QRCode({
      content: `${answer.answer_id}`,
      join: false,
      predefined: false,
      width: 300,
      height: 300
  }).svg();
  if (scorecase == "low") {
    return(
      <div className="summary-body">
        <img
          id="img-covidman"
          src={process.env.PUBLIC_URL + "/images/risk-icon-01.png"}
        />

        <h1 style={{ color: "#e73571" }}>Risk Low</h1>
        <h2 style={{ color: "#e73571" }}>({answer.user_score} Score)</h2>
        {parse(answer.suggest_desc)}
        <div className="summary-qr-code">
          {parse(qrcode)}
        </div>
      </div>
    );
  }
  else if (scorecase == "medium") {
    return(
      <div className="summary-body">
        <img
          id="img-covidman"
          src={process.env.PUBLIC_URL + "/images/risk-icon-02.png"}
        />

        <h1 style={{ color: "#e73571" }}>Risk Medium</h1>
        <h2 style={{ color: "#e73571" }}>({answer.user_score} Score)</h2>
        {parse(answer.suggest_desc)}
        <div className="summary-qr-code">
          {parse(qrcode)}
        </div>
      </div>
    );
  }
  else if (scorecase == "high") {
    return(
      <div className="summary-body">
        <img
          id="img-covidman"
          src={process.env.PUBLIC_URL + "/images/risk-icon-03.png"}
        />

        <h1 style={{ color: "#e73571" }}>Risk High</h1>
        <h2 style={{ color: "#e73571" }}>({answer.user_score} Score)</h2>
        {parse(answer.suggest_desc)}
        <div className="summary-qr-code">
          {parse(qrcode)}
        </div>
      </div>
    );
  }
  else{
    return (<></>);
  }
};

export default Summary;
