import React, { useEffect, useState, useRef } from 'react';
import './css/index.scss';
import ListDetails from './ListDetails';
import {
  Typography,
  Space,
  Row,
  Col,
  Avatar,
  Button,
  Result,
  Input,
} from 'antd';
import {
  UserOutlined,
  ClockCircleOutlined,
  FormOutlined,
  CheckCircleTwoTone,
  ReloadOutlined,
} from '@ant-design/icons';
// import IndexFooter from '../main/IndexFooter';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ApiTranSurvey from '../../Api/ApiTranSurvey';
const { Text, Link, Title } = Typography;
const Detailsindex = (props) => {
  const userState = useSelector((state) => state.user);
  const history = useHistory();
  const [verify, setverify] = useState(true);
  const [result, setResult] = useState(false);
  const [survey, setsurvey] = useState([]);
  const [share, setshare] = useState({});
  const [showShare, setShowShare] = useState(false);
  const [surveyName, setsurveyName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [date_balance, setDateBalance] = useState('');
  const [reRender, setRerender] = useState(false);
  const [totalQuestion, settotalQuestion] = useState(0);
  const [successQuestion, setSuccessQuestion] = useState(0);
  const [answerSuccess, setAnswerSuccess] = useState('');
  const id = new URLSearchParams(props.location.search).get('id');

  useEffect(() => {
    getSurvey();
    getAnswerData();
    return () => {};
  }, []);

  const getSurvey = async () => {
    try {
      Swal.showLoading();
      const survey = await ApiTranSurvey.getById(id);
      if (survey.status == 200) {
        Swal.close();
        const { data } = survey.data;
        console.log(data);
        if (data.length > 0) {
          if (!data[0].question_det) {
            throw 'ไม่พบข้อมูลแบบสอบถาม';
          }

          if (data[0].question_det) {
            setsurvey(data);
          } else {
            throw 'ไม่พบข้อมูลแบบสอบถาม';
          }
        } else {
          throw 'ไม่พบข้อมูลแบบสอบถาม';
        }
      }
    } catch (error) {
      console.log(error);
      Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };

  const getAnswerData = async () => {
    try {
      const result = await ApiTranSurvey.getAnswer(id, userState.userid);
      if (result.status === 200) {
        var { data } = result.data;
        if (data && data.length > 0) {
          throw 'ท่านตอบแบบสอบถามนี้แล้วไม่สามารถตอนแบบสอบถามนี้ซ้ำได้';
        }
      }
    } catch (error) {
      console.log(error);
      Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };

  useEffect(() => {
    let total = 0;
    survey.map((item, index) => {
      total += item.question_det.elements.filter((x) => x.value).length;
    });
    setSuccessQuestion(total);
    return () => {};
  }, [survey]);

  const onCommentClick = (page, item) => {
    let newSurvey = [...survey];
    let newPage = newSurvey.find((x) => x.question_id == page.question_id);
    let newQuestion = newPage.question_det.elements;
    let filter = newQuestion.findIndex((x) => x.name == item.name);
    newQuestion[filter].editor = true;
    setsurvey(newSurvey);
  };

  const onCommentOnChange = (page, item, label) => {
    let newSurvey = [...survey];
    let newPage = newSurvey.find((x) => x.question_id == page.question_id);
    let newQuestion = newPage.question_det.elements;
    let filter = newQuestion.findIndex((x) => x.name == item.name);
    newQuestion[filter].note = label;
    setsurvey(newSurvey);
  };

  const onEvent = (surveyItem, question, type, value) => {
    if (type == 'radiogroup') {
      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_det.elements;
      let filter = newQuestion.findIndex((x) => x.name == question);
      newQuestion[filter].value = value;
      setsurvey(newSurvey);
    } else if (type == 'text') {
      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_det.elements;
      let filter = newQuestion.findIndex((x) => x.name == question);
      newQuestion[filter].value = value;
      setsurvey(newSurvey);
    } else if (type == 'dropdown') {
      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_det.elements;
      let filter = newQuestion.findIndex((x) => x.name == question);
      newQuestion[filter].value = value;

      setsurvey(newSurvey);
    } else if (type == 'file') {
      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_det.elements;
      let filter = newQuestion.findIndex((x) => x.name == question);
      newQuestion[filter].value = value.name;
      setsurvey(newSurvey);
    } else if (type == 'other') {
      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_det.elements;
      let filter = newQuestion.findIndex((x) => x.name == question);
      newQuestion[filter].otherValue = value;
      setsurvey(newSurvey);
    }
    if (type == 'checkbox') {
      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_det.elements;
      let filter = newQuestion.findIndex((x) => x.name == question);
      if (newQuestion[filter].value) {
        if (newQuestion[filter].value.includes(value)) {
          const checkBoxIndex = newQuestion[filter].value.findIndex(
            (x) => x == value
          );
          newQuestion[filter].value.splice(checkBoxIndex, 1);
        } else {
          newQuestion[filter].value.push(value);
        }
      } else {
        newQuestion[filter].value = [];
        newQuestion[filter].value.push(value);
      }
      setsurvey(newSurvey);
    }
  };

  const surveyPageRender = () => {
    let components = [];
    let beforeQuestion = 0;
    survey.map((item, index) => {
      console.log('pagerender', item);
      components.push(
        <div
          style={{
            background: '#fff',
            padding: '20px 20px',
            // marginTop: 20,
            // margin: '15px;0px',
            // margin: '15px 0px',
            borderTop: index == 0 ? 'none' : '1px solid #0c4da2',
          }}
        >
          <div maxWidth='xl'>
            <span className='header-page'>{item.name}</span>
            <div>
              {item?.question_title && (
                <p className='section-head-text-details'>{`${item.question_title}`}</p>
              )}
              {item?.question_desc && (
                <p className='section-head-description-details'>{`${item.question_desc}`}</p>
              )}
            </div>
            <section style={{ marginTop: 20 }}>
              <ListDetails
                beforeQuestion={beforeQuestion}
                survey={item}
                onEvent={onEvent}
                onCommentClick={onCommentClick}
                onCommentOnChange={onCommentOnChange}
              />
            </section>
          </div>
        </div>
      );
      beforeQuestion += item.question_det.elements.length;
    });
    return (
      <div style={{ paddingBottom: 120 }}>
        {components}
        {/* <ReCAPTCHA
          className='d-flex-center'
          style={{ marginTop: 20 }}
          sitekey='6Lfxs6kaAAAAALjK038cIdMbaiqxaH4lC8M5l8rn'
          render='explicit'
          verifyCallback={verifyCallback}
          onloadCallback={callback}
        /> */}
      </div>
    );
  };

  const sendAnswer = async () => {
    Swal.showLoading();
    try {
      const model = {
        survey_id: survey[0].id,
        createby: `${userState.userid}`,
        updateby: `${userState.userid}`,
        record_status: 'A',
        question_det: JSON.stringify(survey[0].question_det),
      };
      let summary_score = 0;

      survey[0].question_det.elements.map((item, index) => {
        var sum_score = 0;
        if (!item.value) {
          return false;
        }
        try {
          if (item.type == 'checkbox') {
            const choice = item.choices
              .filter((x) => item.value.includes(x.value))
              .map((x) => x.text);
            choice.map((text) => {
              var score = text.substring(
                text.lastIndexOf('{{') + 2,
                text.lastIndexOf('}}')
              );
              // sum_score += parseInt(score);
              if (score > sum_score) {
                sum_score = parseInt(score);
              }
            });
          } else if (item.type == 'radiogroup') {
            let choice = item.choices
              .filter((x) => x.value == item.value)
              .map((x) => x.text);
            choice.map((text) => {
              var score = text.substring(
                text.lastIndexOf('{{') + 2,
                text.lastIndexOf('}}')
              );
              if (score > sum_score) {
                sum_score = parseInt(score);
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
        summary_score += sum_score;
      });

      model.summary_score = summary_score;

      const result = await ApiTranSurvey.createAnswer(model);
      if (result.status == 200) {
        Swal.fire('สำเร็จ', 'ส่งแบบประเมินเรียบร้อยแล้ว', 'success').then(
          (result) => {
            history.push(`/summary?id=${id}`);
          }
        );
      }
    } catch (error) {
      console.log(error);
      console.log('answererror', error.response);
    }

    // setResult(true);
  };

  function captchaOnChange(value) {
    console.log('Captcha value:', value);
  }

  var callback = function () {
    console.log('Done!!!!');
  };

  // specifying verify callback function
  var verifyCallback = function (response) {
    if (response) {
      setverify(true);
    }
  };

  return (
    <div>
      <section style={{ marginBottom: 15 }}>
        <div style={{ padding: 10 }}>
          <div onClick={() => history.go(-1)} className='back-btn'>
            <ArrowLeftOutlined style={{ marginRight: 6 }} />
            <label style={{ color: '#08c', fontSize: 16 }}>ย้อนกลับ</label>
          </div>
          <div maxWidth='xl'>
            <Row style={{ marginBottom: 10 }} wrap={false}>
              <Col flex='1' className='d-flex'>
                <label className='project-head'>{projectName}</label>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      {survey.length > 0 && result == false && surveyPageRender()}

      {survey.length > 0 && result == false && (
        <>
          <div className='bottom-send-button-container' style={{ bottom: 0 }}>
            {/* <IndexFooter success={successQuestion} complete={totalQuestion} /> */}
            <Button
              type='primary'
              block
              disabled={successQuestion > 0 && verify == true ? false : true}
              icon={<FormOutlined />}
              size='large'
              className='button-radius sent-question-button'
              style={{
                backgroundColor:
                  successQuestion > 0 && verify == true ? '#0c4da2' : '#ebebeb',
              }}
              onClick={() => {
                sendAnswer();
              }}
            >
              ส่งความคิดเห็น
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Detailsindex;
