import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ProgressBar from '../../Utils/Progress';
import './Home.scss';
import ApiTranEvent from '../../Api/ApiTranEvent';
import ApiTranSurvey from '../../Api/ApiTranSurvey';
import { Collapse, Upload } from 'antd';
import ApiHistory from '../../Api/ApiHistory';
import { ConvertDateShort } from '../../Utils/date';
import { useDispatch, useSelector } from 'react-redux';
import QrReader from 'react-qr-reader';
import Scanner from '../scanner/scanner';

const Home = () => {
  const history = useHistory();
  const userState = useSelector((state) => state.user);
  const [tab1, setTab1] = React.useState(true);
  const [tab2, setTab2] = React.useState(false);
  const [dataHistory, setDataHistory] = useState([]);
  const [scanResult, setScanResult] = useState('');
  const [qrReader1, setqrReader1] = useState([]);
  const [isScanner, setisScanner] = useState(false);

  const { Panel } = Collapse;

  useEffect(() => {
    getCheckInData();
    return () => {};
  }, []);

  const changeScanner = () => {
    setisScanner(!isScanner);
  };

  const getCheckInData = async () => {
    try {
      const result = await ApiTranSurvey.getCheckIn(userState.userid);
      if (result.status === 200) {
        var { data } = result.data;
        setDataHistory(data);
      }
    } catch (error) {}
  };

  const generateHistory = () => {
    return dataHistory.map((item, i) => {
      return (
        <EventListComponent
          data={item}
          history={history}
          type={'close'}
          tab={'history'}
        />
      );
    });
  };

  const propsFile = {
    // m19_file,
    beforeUpload: (file) => {
      // return CheckFile({
      //   file,
      //   size: 10,
      //   type: [],
      // });
    },
    onChange: (info) => {
      // console.log(info);
      // let checkFile = info.fileList.filter((file) => !!file.status);
      // // Accept 5 files only
      // checkFile.forEach(function (file, index) {
      //   console.log(file);
      //   let reader = new FileReader();
      //   let data = {
      //     image: file.name,
      //     filename: file.name,
      //     type: file.type,
      //   };
      //   reader.onload = (e) => {
      //     data.base64 = e.target.result;
      //     file.modelUpload = data;
      //   };
      //   reader.readAsDataURL(file.originFileObj);
      // });
      // // file.status is empty when beforeUpload return false
      // setm19_file(info.fileList.filter((file) => !!file.status));
    },
  };

  const handleScan = (data) => {
    if (data) {
      setScanResult(data);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
  const openImageDialog = () => {
    qrReader1.openImageDialog();
  };

  return (
    <div>
      <div className='table-card-container'>
        <div
          className='tab-item '
          onClick={() => {
            setTab1(true);
            setTab2(false);
          }}
          id={tab1 === true && 'active'}
        >
          <i class='fas fa-poll-h'></i>
          <span>Checkin</span>
        </div>
        <div
          className='tab-item'
          onClick={() => {
            setTab1(false);
            setTab2(true);
          }}
          id={tab2 === true && 'active'}
        >
          <i class='fas fa-history'></i>
          <span>History</span>
        </div>
      </div>
      <div className='tab-body'>
        <div
          className='tab-body-items'
          id={tab1 === true && 'tab-body-items-active'}
        >
          {isScanner ? (
            <Scanner />
          ) : (
            <div className='tab-body-item-detail-text'>
              <h4>Please Scan QR Code</h4>
            </div>
          )}
        </div>

        <div
          className='tab-body-items'
          id={tab2 === true && 'tab-body-items-active'}
        >
          {generateHistory()}
        </div>
      </div>
      <div className='tab-footer'>
        {isScanner ? (
          <button
            onClick={changeScanner}
            className='btn btn-info tab-footer-item'
          >
            <i class='fas fa-back'></i>
            Cancel
          </button>
        ) : (
          <button
            onClick={changeScanner}
            className='btn btn-info tab-footer-item'
          >
            <i class='fas fa-camera'></i>
          </button>
        )}
      </div>
    </div>
  );
};

const EventListComponent = ({ data, history, type, tab = '' }) => {
  return (
    <>
      <div className='card-tab'>
        <div className='card-tab-body'>
          <img src={process.env.PUBLIC_URL + data.image} />
          <div id='flex-body'>
            <label>{data.event_name}</label>
            <p>{data.event_desc}</p>
            <p>{data.userfullname}</p>
          </div>
        </div>
        <div className='card-tab-footer'>
          <div className='card-progress-footer'>
            <i class='fas fa-calendar-alt'></i>
            <span>
              Inspector Date :{' '}
              <span id='black-span'>
                {' '}
                {ConvertDateShort(data.instect_date, 'DD MMM YY HH:mm')}
              </span>
            </span>
          </div>
          <div
            className='card-eye-footer event'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push(`/summary?id=${data.inspect_id}`);
            }}
          >
            <i class='fas fa-eye'></i>
            <p>Detail</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
