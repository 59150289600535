import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Checkbox, Button, Space, Modal, Row, Col,  } from 'antd';
import axios from 'axios';
import './index.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { setLocalStorage } from "../../Utils/localStorage.js";
import { useHistory } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';
import ImageLogo from '../../Assets/icons/logo.png';
import { getQueryParams } from '../../Utils/url';
import { useWindowSize } from '../../Utils/WindowSize';
import ApiUser from '../../Api/ApiUser';
import {VERSION_RELEASE} from '../../env'
const queryString = require('query-string');

const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 5,
  },
};

const Login = (props) => {
  const history = useHistory();
  const [width, height] = useWindowSize();
  const { t, i18n } = useTranslation();
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const userRef = useRef(null);
  const otpReceiveRef = useRef(null);
  const [params, setparams] = useState('');
  const dispatch = useDispatch();

  const tailLayout = {
    wrapperCol: {
      offset: !(width >= 768) ? 6 : 10,
      span: 6,
    },
  };

  useEffect(() => {
    setTimeout(() => {
      userRef.current.focus();
    }, 100);
    return () => {};
  }, []);

  useEffect(() => {
    document.title = 'เข้าสู่ระบบ';
    getLanguage();
  }, []);
  const [loading, setloading] = useState(false);
  const [loadingRegister, setloadingRegister] = useState(false);
  const [loadingOTP, setloadingOTP] = useState(false);
  const [tabProcess, setTabProcess] = useState(1);
  const [type, settype] = useState('login');
  const [user, setuser] = useState({});
  const formRef = useRef(null);
  const formRefOTP = useRef(null);
  const formRefOTPLogin = useRef(null);
  const [base64, setbase64] = useState('');

  const onFinish = async (values) => {
    setuser(values);
    loginApi(values);
  };

  const loginApi = async (values) => {
    values.user_type = "IS";
    console.log("value", values);
    setloading(true);
    setTabProcess(1);
    try {
      console.log("value", values);
      const result = await ApiUser.login(values);
      if (result.status === 200) {
        const { user, token } = result.data;
        console.log("user::", user);
        setloading(false);
        dispatch({ type: "set_user", user: user, token: token });
        console.log(user);
        setLocalStorage("token", token);
        history.push("/home");
      }
    } catch (error) {
      console.log(error);
      setloading(false);
      Modal.error({
        okText: t('close'),
        content: t('signInError'),
        onOk: () => {
          // onReset();
        },
      });
    }
  };

  const onLogin = async () => {
    settype('login');
    formRef.current.submit();
  };

  const getLanguage = async () => {
    const language = await localStorage.getItem('language');
    if (language === null || language === '') {
      setDefaultLanguage('en');
    } else {
      i18n.changeLanguage(language);
      setDefaultLanguage(language);
    }
  };

  const changeLanguage = async (type) => {
    await localStorage.setItem('language', type);
    i18n.changeLanguage(type);
    setDefaultLanguage(type);
  };

  // const { Header, Footer } = Layout;
  return (
    <div className='antd-login-user-layout-container'>
      <Row style={{ height: '100%' }}>
        {width >= 768 && (
          <div
            style={{
              padding: 10,
              backgroundColor: '#fff',
              position: 'absolute',
              display: 'flex',
              zIndex: 999,
              right: 20,
              top: 20,
            }}
          >
            {/* <div
              className={`cursor-pointer language-text ${
                defaultLanguage === 'th' && 'active'
              }`}
              onClick={() => changeLanguage('th')}
            >
              ไทย
            </div> */}
            {/* <div
              className={`cursor-pointer language-text ${
                defaultLanguage === 'en' && 'active'
              }`}
              style={{ marginLeft: 10 }}
              onClick={() => changeLanguage('en')}
            >
              EN
            </div> */}
          </div>
        )}
        <Col span={!(width >= 768) ? 0 : 6}>
          <div className='scg-background'>
            <div className='antd-pro-layouts-user-layout-header'>
              <div>
                <div alt='logo' />
                <img width={200} src={ImageLogo} />
              </div>
            </div>
          </div>
        </Col>
        <Col span={!(width >= 768) ? 24 : 18}>
          {!(width >= 768) && (
            <div
              style={{
                padding: 10,
                backgroundColor: '#fff',
                position: 'absolute',
                display: 'flex',
                zIndex: 999,
                right: 20,
                top: 20,
              }}
            >
              {/* <div
                className={`cursor-pointer language-text ${
                  defaultLanguage === 'th' && 'active'
                }`}
                onClick={() => changeLanguage('th')}
              >
                ไทย
              </div> */}
              {/* <div
                className={`cursor-pointer language-text ${
                  defaultLanguage === 'en' && 'active'
                }`}
                style={{ marginLeft: 10 }}
                onClick={() => changeLanguage('en')}
              >
                EN
              </div> */}
            </div>
          )}

          {tabProcess == 1 && (
            <div className='antd-pro-layouts-user-layout-content'>
              <div className='antd-pro-layouts-user-layout-top'>
                <Space direction='vertical' size='large'>
                  {!(width >= 768) && (
                    <div className='antd-pro-layouts-user-layout-header'>
                      <div>
                        <div alt='logo' />
                        <img
                          src={
                            'https://www.scgpackaging.com/public/frontend/assets/img/icon/logo__scg.svg'
                          }
                        />
                      </div>
                    </div>
                  )}
                  <span className='antd-pro-layouts-user-layout-title'>
                    {t('signIn')}
                  </span>
                </Space>
              </div>
              <div className='antd-pro-pages-user-login-style-main'>
                <Form
                  {...layout}
                  key={'formRegister'}
                  ref={formRef}
                  name='register'
                  onFinish={onFinish}
                  initialValues={{
                    residence: ['zhejiang', 'hangzhou', 'xihu'],
                    prefix: '86',
                  }}
                >
                  <Form.Item
                    placeholder={t('username')}
                    name='username'
                    label={t('username')}
                    rules={[
                      {
                        required: true,
                        message: t('username_require'),
                      },
                    ]}
                  >
                    <Input
                      ref={userRef}
                      autoComplete='off'
                      onKeyDown={(e) =>
                        e.keyCode == 13 ? formRef.current.submit() : ''
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: t('password_require'),
                      },
                    ]}
                    label={t('password')}
                  >
                    <Input
                      autoComplete='off'
                      type='password'
                      onKeyDown={(e) =>
                        e.keyCode == 13 ? formRef.current.submit() : ''
                      }
                    />
                  </Form.Item>
                  <Form.Item {...tailLayout}>
                    <Space>
                      <Button
                        loading={loading}
                        onClick={onLogin}
                        style={{ minWidth: !(width >= 768) ? 130 : 130 }}
                        htmlType='button'
                        type='default'
                      >
                        {t('signIn')}
                      </Button>
                      {/* <Link to={`/register`}>
                        <span className='register-link'>{t(`enroll>>`)}</span>
                      </Link> */}
                    </Space>
                  </Form.Item>
                </Form>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        Version {VERSION_RELEASE}
      </Row>
    </div>
  );
};
export default Login;
