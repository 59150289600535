import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Space, Modal, Row, Col } from 'antd';
import axios from 'axios';
import './index.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';
import ImageLogo from '../../Assets/icons/logo.png';
import { useWindowSize } from '../../Utils/WindowSize';
import ApiUser from '../../Api/ApiUser';
const queryString = require('query-string');
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 5,
  },
};

const Register = (props) => {
  const [width, height] = useWindowSize();
  let history = useHistory();
  const { t, i18n } = useTranslation();
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const userRef = useRef(null);
  const otpReceiveRef = useRef(null);
  const tailLayout = {
    wrapperCol: {
      offset: !(width >= 768) ? 6 : 10,
      span: 6,
    },
  };
  useEffect(() => {
    document.title = 'เข้าสู่ระบบ';
    // const backurl = queryString.parse(props.location.search, {
    //   ignoreQueryPrefix: true,
    // }).backurl;
    // if (!backurl) {
    //   Modal.error({
    //     okText: t('close'),
    //     content: t('backurl_require'),
    //     onOk: () => {
    //       window.location.reload();
    //       // onReset();
    //     },
    //   });
    // } else {
    //   setbackURLState(backurl);
    // }
    getLanguage();
  }, []);
  const [backURLState, setbackURLState] = useState('');
  const [loading, setloading] = useState(false);
  const [loadingRegister, setloadingRegister] = useState(false);
  const [loadingOTP, setloadingOTP] = useState(false);
  const [tabProcess, setTabProcess] = useState(1);
  const [type, settype] = useState('login');
  const [user, setuser] = useState({});
  const formRef = useRef(null);
  const formRefOTP = useRef(null);
  const formRefOTPLogin = useRef(null);
  const [base64, setbase64] = useState('');

  useEffect(() => {
    setTimeout(() => {
      userRef.current.focus();
    }, 100);
    return () => {};
  }, []);

  const onFinish = async (values) => {
    setuser(values);
    registerApi(values);
  };

  const registerApi = async (values) => {
    console.log("value", values);
    setloadingRegister(true);
    try {
      var model = {
        username: values.username,
        password: values.password,
        userfullname: values.userfullname,
        useremail: values.useremail,
        useridcard: values.useridcard,
        usermobile: values.usermobile,
        usertype: "BE",
        is_employee: 0,
        company: values.company,
      }
      const result = await ApiUser.insert(model);
      if (result.status === 200) {
        const { data } = result.data;
        history.push("/login");
      }
    } catch (error) {
      console.log(error);
      setloadingRegister(false);
      Modal.error({
        okText: t('close'),
        content: t('signInError'),
        onOk: () => {
          // onReset();
        },
      });
    }
  };

  const onRegister = async () => {
    settype('register');
    formRef.current.submit();
  };

  const getLanguage = async () => {
    const language = await localStorage.getItem('language');
    if (language === null || language === '') {
      setDefaultLanguage('en');
    } else {
      i18n.changeLanguage(language);
      setDefaultLanguage(language);
    }
  };

  const changeLanguage = async (type) => {
    await localStorage.setItem('language', type);
    i18n.changeLanguage(type);
    setDefaultLanguage(type);
  };

  // const { Header, Footer } = Layout;
  return (
    <div className='antd-login-user-layout-container'>
      <Row style={{ height: '100%' }}>
        {!(width < 768) && (
          <div
            style={{
              padding: 10,
              backgroundColor: '#fff',
              position: 'absolute',
              display: 'flex',
              zIndex: 999,
              right: 20,
              top: 20,
            }}
          >
            <div
              className={`cursor-pointer language-text ${
                defaultLanguage === 'th' && 'active'
              }`}
              onClick={() => changeLanguage('th')}
            >
              ไทย
            </div>
            <div
              className={`cursor-pointer language-text ${
                defaultLanguage === 'en' && 'active'
              }`}
              style={{ marginLeft: 10 }}
              onClick={() => changeLanguage('en')}
            >
              EN
            </div>
          </div>
        )}
        <Col span={width < 768 ? 0 : 6}>
          <div className='scg-background'>
            <div className='antd-pro-layouts-user-layout-header'>
              <div>
                <div alt='logo' />
                <img width={200} src={ImageLogo} />
              </div>
            </div>
          </div>
        </Col>
        <Col span={width < 768 ? 24 : 18}>
          {width < 768 && (
            <div
              style={{
                padding: 10,
                backgroundColor: '#fff',
                position: 'absolute',
                display: 'flex',
                zIndex: 999,
                right: 20,
                top: 20,
              }}
            >
              <div
                className={`cursor-pointer language-text ${
                  defaultLanguage === 'th' && 'active'
                }`}
                onClick={() => changeLanguage('th')}
              >
                ไทย
              </div>
              <div
                className={`cursor-pointer language-text ${
                  defaultLanguage === 'en' && 'active'
                }`}
                style={{ marginLeft: 10 }}
                onClick={() => changeLanguage('en')}
              >
                EN
              </div>
            </div>
          )}

          {tabProcess == 1 && (
            <div className='antd-pro-layouts-user-layout-content'>
              <div className='antd-pro-layouts-user-layout-top'>
                <Space direction='vertical' size='large'>
                  {width < 768 && (
                    <div className='antd-pro-layouts-user-layout-header'>
                      <div>
                        <div alt='logo' />
                        <img
                          src={
                            'https://www.scgpackaging.com/public/frontend/assets/img/icon/logo__scg.svg'
                          }
                        />
                      </div>
                    </div>
                  )}
                  <span className='antd-pro-layouts-user-layout-title'>
                    {t('register')}
                  </span>
                </Space>
              </div>
              <div className='antd-pro-pages-user-login-style-main'>
                <Form
                  {...layout}
                  // {...formItemLayout}
                  // form={form}
                  key={'formRegister'}
                  ref={formRef}
                  name='register'
                  onFinish={onFinish}
                  initialValues={{
                    residence: ['zhejiang', 'hangzhou', 'xihu'],
                    prefix: '86',
                  }}
                >
                  <Form.Item
                    placeholder={t('username')}
                    name='username'
                    label={t('username')}
                    rules={[
                      {
                        required: true,
                        message: t('username_require'),
                      },
                    ]}
                  >
                    <Input
                      ref={userRef}
                      autoComplete='off'
                    />
                  </Form.Item>
                  <Form.Item
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: t('password_require'),
                      },
                    ]}
                    label={t('password')}
                  >
                    <Input
                      autoComplete='off'
                      type='password'
                    />
                  </Form.Item>
                  <Form.Item
                    name='userfullname'
                    rules={[
                      {
                        required: true,
                        message: t('userfullname_require'),
                      },
                    ]}
                    label={t('userfullname')}
                  >
                    <Input
                      type='text'
                    />
                  </Form.Item>
                  <Form.Item
                    name='useremail'
                    rules={[
                      {
                        required: true,
                        message: t('useremail_require'),
                      },
                    ]}
                    label={t('useremail')}
                  >
                    <Input
                      type='email'
                    />
                  </Form.Item>
                  <Form.Item
                    name='usermobile'
                    rules={[
                      {
                        required: true,
                        message: t('usermobile_require'),
                      },
                    ]}
                    label={t('usermobile')}
                  >
                    <Input
                      type='number'
                    />
                  </Form.Item>
                  <Form.Item
                    name='useridcard'
                    rules={[
                      {
                        required: true,
                        message: t('useridcard_require'),
                      },
                    ]}
                    label={t('useridcard')}
                  >
                    <Input
                      type='number'
                    />
                  </Form.Item>
                  <Form.Item
                    name='company'
                    rules={[
                      {
                        required: true,
                        message: t('company_require'),
                      },
                    ]}
                    label={t('company')}
                  >
                    <Input
                      type='text'
                    />
                  </Form.Item>
                  <Form.Item {...tailLayout}>
                    <Space>
                      <Button
                        loading={loadingRegister}
                        onClick={onRegister}
                        style={{ minWidth: width < 768 ? 130 : 130 }}
                        htmlType='button'
                        type='default'
                      >
                        {t('register')}
                      </Button>
                      <Link to={`/login`}>
                        <span className='register-link'>{t(`signIn>>`)}</span>
                      </Link>
                    </Space>
                  </Form.Item>
                  {/* </Space> */}
                </Form>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default Register;
