import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './Navbar.scss'
import ApiContent from "../../Api/ApiContent";
const Branner = () => {
  const [dataContent, setDataContent] = useState([]);

  useEffect(() => {
    getData();
    return () => {};
  }, []);

  const getData = async () => {
    try {
      const result = await ApiContent.get();
      if (result.status === 200) {
        const { data } = result.data;
        for (let i = 0; i < data.length; i++) {
          data[i].order = i + 1;
        }
        setDataContent(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000,
        rtl: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    return (
        <div>
             <Slider {...settings}>
               {dataContent.map((item, i) => {
                 return(
                  <div className="carousel-image-container" >
                      <img src={process.env.PUBLIC_URL+ item.image} />
                  </div>
                 );
               })}
             </Slider>
        </div>
    )
}

export default Branner
