import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Button, Space, Modal, Row, Col } from 'antd';
import ApiTranSurvey from "../../Api/ApiTranSurvey";
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import "./checkin.scss";
import { useDispatch, useSelector } from "react-redux";

const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 5,
    },
  };

const CheckIn = (props) => {
    const history = useHistory();
    const id = new URLSearchParams(props.location.search).get("id");
    const userState = useSelector((state) => state.user);
    const [answer, setAnswer] = useState({});
    const [scorecase, setScoreCase] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const formRef = useRef(null);
    
    const [form] = Form.useForm();

    useEffect(() => {
        getAnswerData();
        
        navigator.geolocation.getCurrentPosition(function(position) {
            console.log("Latitude", position.coords.latitude);
            console.log("Longitude", position.coords.longitude);
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            form.setFieldsValue({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            });
        });
      return () => {};
    }, []);

    const getAnswerData = async () => {
      try {
        const result = await ApiTranSurvey.getAnswerById(id);
        if (result.status === 200) {
          var { data } = result.data;
          if (!data || data.length == 0) {
            throw 'ไม่พบข้อมูลการตอบแบบสอบถาม';
          }
          console.log("data", data[0]);
          setAnswer(data[0]);
          if (data[0].user_score >= data[0].score_low_min && data[0].user_score <= data[0].score_low_max) {
            setScoreCase("low")
          }
          else if (data[0].user_score >= data[0].score_medium_min && data[0].user_score <= data[0].score_medium_max) {
            setScoreCase("medium")
          }
          else if (data[0].user_score >= data[0].score_high_min && data[0].user_score <= data[0].score_high_max) {
            setScoreCase("high")
          }
        }
        // generateAnswerDetail();
      } catch (error) {
        console.log(error);
        // Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        //   history.go(-1);
        // });
      }
    };

    const onFinish = async (values) => {
        console.log("values", values);
        console.log("userState", userState);
        try {
            var model = {
              answer_id: id,
              createby: userState.userid,
              temperature: values.temperature,
              latitude: values.latitude,
              longitude: values.longitude,
            }
            const result = await ApiTranSurvey.createAnswerInspect(model);
            if (result.status === 200) {
              const { data } = result.data;
              Swal.fire("Success", "Check In Success", 'success').then(
                (result) => {
                  history.push(`/home`);
                }
              );
            }
          } catch (error) {
            console.log(error);
            Modal.error({
              okText: 'close',
              content: error,
              onOk: () => {
                // onReset();
              },
            });
          }
    };

    const onSubmit = async () => {
      formRef.current.submit();
    };

    return (
      <div>
            <div>
                <div className="card-tab">
                    <div className="card-tab-footer-hr"></div>
                    <div className="card-tab-footer-summary">
                        <p>{answer.userfullname} </p>
                        <p>Estimate Result : {scorecase == "low" ? "Low Risk" : scorecase == "medium" ? "Medium Risk" : "High Risk"}, Score: ({answer.user_score}) </p>
                    </div>
                </div>
                <div className="summary-body">
                    <Form
                        form={form}
                        {...layout}
                        // {...formItemLayout}
                        key={'formRegister'}
                        ref={formRef}
                        name='register'
                        onFinish={onFinish}
                    >
                        <Form.Item
                            placeholder={'Temperature'}
                            name='temperature'
                            label={'Temperature ( ํC )'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Temperature require',
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                autoComplete='off'
                            />
                        </Form.Item>
                        <Form.Item
                            placeholder={'Longitude'}
                            name='latitude'
                            label={'Latitude'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Latitude require',
                                },
                            ]}
                        >
                            <Input
                                value={latitude}
                                readOnly
                            />
                        </Form.Item>
                        <Form.Item
                            placeholder={'Longitude'}
                            name='longitude'
                            label={'Longitude'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Longitude require',
                                },
                            ]}
                        >
                            <Input
                                value={longitude}
                                readOnly
                            />
                        </Form.Item>
                    </Form>
                </div>
                {/* <SummaryBody answer={answer} scorecase={scorecase}/> */}
                <div className="card-tab-footer-hr2"></div>
                <div className="summary-body-footer">
                    <p id={"solid-1"} style={{cursor: "pointer"}} onClick={onSubmit}>
                        <span className="fas fa-save"></span>
                        Save
                    </p>
                    <p id={"solid-1"} style={{cursor: "pointer"}} onClick={() => {
                        history.push("/home");
                    }}>
                        <span className="fas fa-times-circle"></span>
                        Close
                    </p>
                    {/* <p id={"solid-2"}>
                        <span className="fas fa-download"></span>
                        Download
                    </p> */}
                </div>
            </div>
      </div>
    );

}
export default CheckIn;
