import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  BrowserRouter,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Home from '../Home/Home';
import summary from '../summary/Summary';
import Survey from '../survey/index';
import SurveyHistory from '../surveyHistory/index';
import CheckIn from '../checkin/index';
import Branner from './Branner';
import Scanner from '../scanner/scanner';
import './Navbar.scss';
import { useHistory } from 'react-router-dom';
const Narbar = (props) => {
  const [clicked, setClicked] = React.useState(false);
  const history = useHistory();
  const pageData = useSelector((state) => state.page);
  useEffect(() => {
    return () => {};
  }, []);
  return (
    <div className='bg'>
      <nav className='NavbarItems'>
        <h1
          className='navbar-logo'
          onClick={() => {
            history.push('/home');
          }}
        >
          SCG PACKAGING
        </h1>
        <div
          className='menu-icon'
          onClick={() => {
            setClicked(!clicked);
          }}
        >
          <i className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
        <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
          <li>
          <label className='nav-links' onClick={() => {
              history.push("/home");
              history.go(0);
            }}>
              Home
            </label>
          </li>
          <li>
            <label className='nav-links' onClick={() => history.push("/")}>
              Logout
            </label>
          </li>
        </ul>
      </nav>
      <div className='branner-container'><Branner /></div>
      <main>
        <BrowserRouter>
          <Switch>
            <Route path='/home' component={Home} />
            <Route path='/summary' component={summary} />
            <Route path='/survey' component={Survey} />
            <Route path='/surveyHistory' component={SurveyHistory} />
            <Route path='/checkin' component={CheckIn} />
            <Route path='/scanner' component={Scanner} />
            {/* <Route path='/*' component={Home} /> */}
          </Switch>
        </BrowserRouter>
      </main>
    </div>
  );
};

export default Narbar;
